/**
 * Implement Gatsby's SSR (Server Side Rendering) APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/ssr-apis/
 */
import React from "react"
import { SiteContextProvider } from "./src/custom/SiteContextProvider"
import { SearchContextProvider } from "@organic-return/foundation-react/src/components/Search/SearchContext"
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3"
import "./src/styles/example-theme.css"

export const wrapRootElement = ({ element }) => (
    <SiteContextProvider>
        <SearchContextProvider>
            <GoogleReCaptchaProvider reCaptchaKey={process.env.GOOGLE_RECAPTCHA_SITE_KEY} scriptProps={{ async: true, defer: true }}>
                {element}
            </GoogleReCaptchaProvider>
        </SearchContextProvider>
    </SiteContextProvider>
)

